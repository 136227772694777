 .card.initial {
  text-align: center; /* Center the content */
}

.initial-image {
  width: 60px; 
  height: 60px; 
  margin: 0 auto 20px; /* Center the image and add bottom margin */
  display: block; /* Ensure the image is centered properly */
}

.initial-description {
  flex-grow: 1; /* Make the description occupy all available vertical space */
}

.initialButton-selected{
  background-color: lightblue;
}

.initialButton-selected:hover{
  background-color: rgb(118, 187, 210);
}
