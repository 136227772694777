body {background-color: rgb(213, 233, 255);}

.container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers elements horizontally */
  margin-top: 10vh;
}

.first-cards, .second-cards, .third-cards {
  display: flex; /* Displays elements in a horizontal row */
  margin: 3vh 0 3vh 0; /* Spacing between rows */
  margin-top: 10px;
  margin-bottom: 10px;
}

.card {
  width: 260px; /* Desired width of the card */
  min-width: 260px;
  border: 1px solid #0e0e0e; /* Thin border */
  border-radius: 10px; /* Rounded corners */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: stretch; /*  all cards have the same height */
  margin-right: 15px; /* Space between cards */
  padding: 15px; /* Internal spacing */
}

.title {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
}

div {
  margin-bottom: 10px;
}

.specific-image{
  text-align: center;
  max-width: 100%;
  height: auto;
}

.button {
  /* background-color: #4CAF50;  *//* Button background color */
  color: white; /* Button text color */
  padding: 10px 20px; /* Internal spacing */
  font-size: 16px; /* Font size */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Change cursor on hover */
  margin-top: auto;
  width: 100%;
}

.url-privacy {
  color: #3498db;
  text-decoration: none;
}

.Helpbutton {

  color: white; /* Button text color */
  padding: 10px 10px; /* Internal spacing */
  font-size: 12px; /* Font size */
  border: none; /* Remove border */
  border-radius: 40px; /* Rounded corners */
  cursor: pointer; /* Change cursor on hover */
  margin-top: auto;
  width: 18%;
  text-align: center;
  display: flex;
  align-self: center;
  content: center;

}

.button.unselected, .button.disabled, .button.enabled, .button.send{
  background-color: rgb(134, 194, 253);
}

.button.unselected:hover, .button.disabled:hover, .button.enabled:hover, .button.send:hover {
  background-color: #3f9342;
}

.button.selected{
  background-color: lightblue;
}

.button.selected:hover{
  background-color: rgb(118, 187, 210);
}

.select-input, .text-input, .textarea-input{
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 100%;
}

.select-input-1-3{
  width: 100%;
  padding: 10px;
  margin-top: 2px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  max-width: 33%;
  min-width: 33%;
}

.number-input{
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  max-width: 33%;
  min-width: 33%;
}

.indication-italic{
  max-width: 100%;
  min-width: 100%;
  font-style: italic;
  font-size: 12px;

}


.title-label-within-card
{
  
  max-width: 100%;
  min-width: 100%;
  text-decoration: underline;

}

.small-input{
  padding: 10px;
  margin-top: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  max-width: 33%;
  min-width: 33%;
}


.vertical-mode {
  display: block;
  border-radius: 10px;
  width: 100% !important; 
}



@media (max-width: 970px){
  .specific-cards {
    width: 200px !important;
  }
  .checkbox-cards{
    width: 200px !important;
  }
  .initial-cards{
    width: 200px !important;
  }
}

@media (max-width: 940px){
  .card{
    margin: 0;
  }
  .first-cards, .second-cards, .third-cards{
    display: block;
    border: 1px solid black;
    border-radius: 10px;
  }
  .initial-cards {
    margin-right: 0px !important;
  }

  .checkbox-cards{
    margin-right: 0px !important;
  }

  .specific-cards{
    margin-right: 0px !important;
  }
}

/*-----------------------*/

.modal-summary {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px); /* Blur Effect */
  overflow-y: auto;
}

.modal-confirmation-summary {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #5a5a5a;
  min-width: 20vw;
  max-width: 500px;
  color: white;
}

.confirmation-title-summary {
  font-size: 20px;
}

.confirmation-message-summary {
  margin-bottom: 20px;
}

.confirmation-buttons-summary {
  display: flex;
  justify-content: center;
}

/* Button styles */
.button-summary {
  background-color: #4d9aff; 
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;
}

/* Hover effect */

.button-summary:hover{
  background-color: rgba(131, 205, 254, 0.9); /* Brighter background color */
  box-shadow: 0 0 10px rgba(255, 77, 77, 0.5); /* More pronounced shadow */
  }

  .question {
    
    color: white; 
  }
  
  .answer {
    color: white; 
  }


  /* ---MODAL--- */

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
  margin: auto;
  width: 80%;
  max-width: 100vh;
  border-radius: 10px;
  position: relative;
}

.close {
  color: #aaa;
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer; 
}

.close:hover,
.close:focus {
  color: white;
  text-decoration: none;
  cursor: pointer; 
}

.specific-image-modal {
  text-align: center;
  width: 100%; 
  height: auto;
  border-radius: 10px;
}

.thumbnail-text {
  cursor: pointer; /* Change cursor to pointer (hand) */
  text-align: center;
}

.image-preview{
  margin-top: 20px;
  text-align: center;
}


/*  ------------------------*/

.checkbox-options-specific-card {
  display: flex;
  flex-wrap: wrap;
  gap: 7px; 
}

.checkbox-options.inline {
  flex-direction: row;
}




.button-default-values {
  background-color: #436D44;
  color: white;
  padding: 2px px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: auto;
  width: 70%;
  margin-left: 15%;
}