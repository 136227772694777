.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .home-title {
    font-size: 40px;
    margin: 10px 0;
  }
  
  .home-card-title {
    margin-bottom: 20px;
    text-decoration: none;
  }
  
  .home-intro {
    font-size: 1.2rem;
    margin-bottom: 40px;
    max-width: 600px;
  }
  
  .home-cards-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-width: 1000px;
  }
  
  .home-card {
    width: 260px; 
    border: 1px solid #0e0e0e;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 15px;
    text-align: center;
    transition: transform 0.3s ease;
  }

  .home-card a {
    text-decoration: none;
    color: inherit; 
    margin-top: 15px;
  }
  
  .home-card:hover {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(0, 0, 139, 0.6);
  }
  
  .home-initial-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-bottom: 1px solid #0e0e0e;
    margin-bottom: 15px;
  }
  
 
  
  @media (max-width: 970px) {
    .home-card {
      width: 200px !important;
    }
  }
  
  @media (max-width: 800px) {
    .home-card {
      margin: 0;
    }
  
    .home-cards-grid {
      display: block;
    }
  
    .home-card {
      margin-bottom: 20px;
    }
  }