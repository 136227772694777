.navbar-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1001;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5vh 10px;
    background: rgba(0, 0, 0, 0.28);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    height: 4rem;
  }
  
  .navbar-left {
    display: flex;
    align-items: center;
  }

  .navbar-left.secondItem {
    margin-left: 30px;
  }
  
  .nav-link {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    transition: color 0.3s ease;
    cursor: pointer;
  }
  
  .nav-link:hover {
    color: rgb(213, 233, 255);
    
  }
  
  .main-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .button-link {
    margin: 10px;
  }
  
  .main-button {
    padding: 15px 30px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .main-button:hover {
    background-color: #0056b3;
  }