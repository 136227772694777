.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0; 
    right: 0; 
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    text-align: center;
    z-index: 1000;
    min-width: 250px;
    margin-bottom: 0px;
}

.cookie-banner p {
    margin: 0 0 10px; 
    padding: 0;
    max-width: 100%; 
    word-wrap: break-word; 
    text-align: center;
}

.cookie-banner button-container {
    display: flex;
    gap: 10px; 
}

.cookie-banner button {
    background-color: #fff;
    color: #000;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 10px;
}